<template>
  <div>
    <div class="title"></div>
    <div class="botcont">
      <div class="botmain">
        <img src="../../../../assets/water/logo5.png" alt class="botimg" />
        <div class="bottext">本月报警设备数量</div>
        <div>
          <span class="botnum">{{alarmDeviceQuantity}}</span>
          <span class="danwei">台</span>
        </div>
      </div>
      <div class="botmain">
        <img src="../../../../assets/water/logo6.png" alt class="botimg" />
        <div class="bottext">本月维护设备数量</div>
        <div>
          <span class="botnum">{{maintenanceDeviceQuantity}}</span>
          <span class="danwei">台</span>
        </div>
      </div>
      <div class="botmain">
        <img src="../../../../assets/water/logo6.png" alt class="botimg" />
        <div class="bottext">未维护设备数量</div>
        <div>
          <span class="botnum1">{{notMaintenanceDeviceQuantity}}</span>
          <span class="danwei">台</span>
        </div>
      </div>
    </div>

    <div class="botcont">
      <div class="botmain">
        <img src="../../../../assets/water/logo5.png" alt class="botimg" />
        <div class="bottext">所有报警设备数量</div>
        <div>
          <span class="botnum">{{alarmDeviceTotal}}</span>
          <span class="danwei">台</span>
        </div>
      </div>
      <div class="botmain">
        <img src="../../../../assets/water/logo6.png" alt class="botimg" />
        <div class="bottext">所有维护设备数量</div>
        <div>
          <span class="botnum">{{maintenanceDeviceTotal}}</span>
          <span class="danwei">台</span>
        </div>
      </div>
      <div class="botmain"></div>
    </div>
  </div>
</template>
<script>
import { deviceMaintenanceOverview } from "@/api";
export default {
  data () {
    return {
      alarmDeviceQuantity: 0,
      alarmDeviceTotal: 0,
      maintenanceDeviceQuantity: 0,
      maintenanceDeviceTotal: 0,
      notMaintenanceDeviceQuantity: 0
    };
  },
  methods: {
    getflow () {
      deviceMaintenanceOverview().then(res => {
        this.alarmDeviceQuantity = res.alarmDeviceQuantity;
        this.alarmDeviceTotal = res.alarmDeviceTotal;
        this.maintenanceDeviceQuantity = res.maintenanceDeviceQuantity;
        this.maintenanceDeviceTotal = res.maintenanceDeviceTotal;
        this.notMaintenanceDeviceQuantity = res.notMaintenanceDeviceQuantity;
        console.log(res);
      });
    }
  },
  created () {
    this.getflow();
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 50px;
  width: 549px;
  background: url("../../../../assets/water/leftbottom.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
}
.botcont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .botmain {
    color: white;
    text-align: center;
    width: 33.3%;
    margin-top: 10px;
    .botimg {
      width: 50px;
      height: 50px;
      margin: 0 auto;
    }
    .bottext {
      font-size: 18px;
    }
    .botnum {
      color: #00ddff;
      font-size: 26px;
      margin-right: 3px;
      font-weight: bold;
    }
    .botnum1 {
      color: #ff0000;
      font-size: 26px;
      margin-right: 3px;
      font-weight: bold;
    }
    .danwei {
      font-size: 14px;
    }
  }
}
</style>
