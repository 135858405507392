<template>
  <div>
    <div class="title"></div>
    <div class="alarmData_content">
      <el-row class="alarmData_title">
        <el-col :span="8">所属学校</el-col>
        <el-col :span="6">设备位置</el-col>
        <el-col :span="5">报警时间</el-col>
        <el-col :span="5">当前状态</el-col>
      </el-row>
      <div class="swiper swiper_alarmData" v-if="alarmDataList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in alarmDataList" class="swiper-slide" :key="index">
            <el-col :span="8">{{el.school}}</el-col>
            <el-col :span="6">{{el.location}}</el-col>
            <el-col :span="5">{{el.eventtime}}</el-col>
            <el-col :span="5" style="color:#00FF00" v-if="el.result==='已维护'">已维护</el-col>
            <el-col :span="5" style="color:#FF0000" v-if="el.result==='未维护'">未维护</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Autoplay } from "swiper";
import "swiper/swiper.scss";
import { alarmStatisticThisMonth } from "@/api";

Swiper.use([Autoplay]);

export default {
  data() {
    return {
      alarmDataList: []
    };
  },
  methods: {
    initSwiper() {
      this.$nextTick(() => {
        if (this.alarmDataList && this.alarmDataList.length > 7) {
          new Swiper(".swiper_alarmData", {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: "vertical",
            slidesPerView: "auto",
            loop: true,
            speed: 1500
          });
        }
        if (this.alarmDataList && this.alarmDataList.length <= 7) {
          new Swiper(".swiper_alarmData", {
            direction: "vertical",
            slidesPerView: "auto",
            // loop: true,
            speed: 1500
          });
        }
      });
    },
    init() {
      alarmStatisticThisMonth().then(res => {
        this.alarmDataList = res;
        console.log(res);
        console.log(this.alarmDataList.length);
        this.initSwiper();
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 50px;
  width: 549px;
  background: url("../../../../assets/water/righttop.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
}
.alarmData_content {
  padding: 24px 24px 0 25px;
  background: rgba(4, 32, 49, 0.4);
  .alarmData_title {
    font-size: 16px;
    font-weight: bold;
    color: #15eaff;
    display: flex;
    .el-col {
      float: none;
    }
  }
  .swiper_alarmData {
    overflow: hidden;
    height: 280px;
    margin-top: 20px;
    .swiper-slide {
      display: flex;
      margin-bottom: 25px;
      height: 15px;
      .el-col {
        float: none;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
</style>