<template>
  <div>
    <div class="title"></div>
    <div class="centerline">
      <div class="centermain" style="margin-right:95px">
        <div class="centertext">本月累计用水</div>
        <div>
          <span class="centernum">{{totalFlow}}</span>
          吨
        </div>
      </div>
      <div class="centermain">
        <div class="centertext">本月各设备平均用水量</div>
        <div>
          <span class="centernum">{{averageFlow}}</span>
          吨
        </div>
      </div>
    </div>
    <div class="centerline">
      <div class="centermain" style="margin-right:95px">
        <div class="centertext">日累计用水量</div>
        <div>
          <span class="centernum">{{dailyFlow}}</span>
          吨
        </div>
      </div>
      <div class="centermain">
        <div class="centertext">本月设备最高用水量</div>
        <div>
          <span class="centernum">{{maxFlow}}</span>
          吨
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getflow } from "@/api";
export default {
  data() {
    return {
      totalFlow: 0,
      averageFlow: 0,
      dailyFlow: 0,
      maxFlow: 0
    };
  },
  methods: {
    getflow() {
      getflow().then(res => {
        this.totalFlow = res[0].totalFlow;
        this.averageFlow = res[1].averageFlow;
        this.dailyFlow = res[2].dailyFlow;
        this.maxFlow = res[3].maxFlow;
        console.log(res);
      });
    }
  },
  created() {
    this.getflow();
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 50px;
  width: 549px;
  background: url("../../../../assets/water/leftcenter.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
}

.centerline {
  color: white;
  display: flex;
  align-items: center;
  .centermain {
    font-size: 18px;
    margin-top: 24px;
    .centertext {
      margin-bottom: 10px;
    }
    .centernum {
      color: #ffd600;
      font-size: 28px;
      font-weight: bold;
    }
  }
}
</style>