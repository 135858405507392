<template>
  <div class="water">
    <water-header />
    <div id="fullscreen"></div>
    <div class="left">
      <LeftTop />
      <LeftCenter />
      <LeftBottom />
    </div>
    <div class="center">
      <CenterBottom />
    </div>

    <div class="right">
      <RightTop />
      <RightBottom />
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import AMapUI from "AMapUI";
import WaterHeader from "./components/header";
import LeftTop from "./components/lefttop";
import LeftCenter from "./components/leftcenter";
import LeftBottom from "./components/leftbottom";
import RightTop from "./components/righttop";
import RightBottom from "./components/rightbottom";
import CenterBottom from "./components/centerbottom";
import { unitStatistic } from "@/api";

export default {
  components: {
    WaterHeader,
    LeftTop,
    LeftCenter,
    LeftBottom,
    RightTop,
    RightBottom,
    CenterBottom
  },
  data () {
    return {
      map: null,
      schoollist: []
    };
  },
  created () {
    this.init();
  },
  mounted () {
    window.onload = function () {
      //   let h = document.getElementsByClassName("left")[0].offsetHeight;
      //   const height = h + 67;
      //   // console.log(h)
      //   document.getElementsByClassName("air")[0].style.height = height + "px";
    };

    // console.log('jsonmap', jsonmap)
  },
  methods: {
    init () {
      unitStatistic().then(res => {
        this.schoollist = res;
        console.log(res);
        this.loadMap();
      });
    },
    loadMap () {
      this.map = new AMap.Map("fullscreen", {
        resizeEnable: true,
        mapStyle: "amap://styles/darkblue",
        turboMode: false,
        showIndoorMap: false,
        defaultCursor: "pointer",
        showBuildingBlock: false,
        showLabel: false,
        forceVector: true,

        // center: [120.200968, 30.236638],
        // zooms: [4, 18],
        zoom: 15
      });
      this.map.setZoomAndCenter(14, [120.180968, 30.228638]);
      // this.Action(jsonmap)
      this.loadMapData(330102);
    },
    loadMapData (areaCode) {
      const _this = this;
      AMapUI.loadUI(["geo/DistrictExplorer"], function (DistrictExplorer) {
        //创建一个实例
        var districtExplorer = new DistrictExplorer({
          map: _this.map
        });
        var adcode = areaCode;

        districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
          // console.log('areaNode', areaNode)
          //更新地图视野
          // _this.map.setBounds(areaNode.getBounds(), null, null, true);

          //清除已有的绘制内容
          districtExplorer.clearFeaturePolygons();

          //绘制子区域
          districtExplorer.renderSubFeatures(areaNode, function () {
            // var fillColor = colors[i % colors.length];
            // var strokeColor = colors[colors.length - 1 - i % colors.length];
            return {
              cursor: "default",
              bubble: true,
              strokeColor: "#12558A", //线颜色
              strokeOpacity: 1, //线透明度
              strokeWeight: 1, //线宽
              fillColor: "#12558A", //填充色
              fillOpacity: 0.35 //填充透明度
            };
          });

          //绘制父区域
          districtExplorer.renderParentFeature(areaNode, {
            cursor: "default",
            bubble: true,
            strokeColor: "#12558A", //线颜色
            strokeOpacity: 0.6, //线透明度
            strokeStyle: "dashed",
            strokeWeight: 3, //线宽
            fillColor: "#12558A", //填充色
            fillOpacity: 0.35 //填充透明度
          });
        });
      });

      this.addmark();
    },

    addmark () {
      const markerList = [];
      const _this = this;
      const icon = new AMap.Icon({
        size: new AMap.Size(20, 20), // 图标尺寸
        image: "https://statics.terabits.cn/mainimg/water1.png", // Icon的图像
        // imageOffset: new AMap.Pixel(0, -60), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(20, 20) // 根据所设置的大小拉伸或压缩图片
      });

      const icon1 = new AMap.Icon({
        size: new AMap.Size(20, 20), // 图标尺寸
        image: "https://statics.terabits.cn/mainimg/water2.png", // Icon的图像
        // imageOffset: new AMap.Pixel(0, -60), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(20, 20) // 根据所设置的大小拉伸或压缩图片
      });
      this.schoollist.map(el => {
        if (
          el.name === "天长小学(孝女路校区)" ||
          el.name === "教育科学研究所附属小学" ||
          el.name === "凤凰小学（钱江校区）" ||
          el.name === "大学路小学(大学路校区)" ||
          el.name === "杭州新世纪外国语学校(湖滨校区)" ||
          el.name === "开元中学" ||
          el.name === "回族穆兴小学（小营校区）" ||
          el.name === "胜利小学(新城校区)" ||
          el.name === "杭州娃哈哈双语学校" ||
          el.name === "新世纪外国语学校（湖畔校区）"
        ) {
          el.temp = new AMap.Marker({
            map: this.map,
            position: [el.lng, el.lat],
            title: el.name,
            icon: el.type === "ok" ? icon : el.type === "err" ? icon1 : ""
          });

          el.temp.setLabel({
            direction: "bottom",
            offset: new AMap.Pixel(0, 0) //设置文本标注偏移量
          });

          el.temp.content =
            "<div style='color:white;font-size:18px;font-weight:bold'>" +
            el.name +
            "</div>" +
            el.info;

          AMap.event.addListener(el.temp, "click", function () {
            // console.log(e.target.getExtData())
            _this.dotItem(el.temp);
          });
          markerList.push(el.temp);
        } else {
          el.temp = new AMap.Marker({
            map: this.map,
            position: [el.lng, el.lat],
            title: el.name,
            icon: el.type === "ok" ? icon : el.type === "err" ? icon1 : ""
          });

          el.temp.setLabel({
            direction: "bottom",
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: "<div class='info'>" + el.name + "</div>" //设置文本标注内容
          });

          el.temp.content =
            "<div style='color:white;font-size:18px;font-weight:bold'>" +
            el.name +
            "</div>" +
            el.info;

          AMap.event.addListener(el.temp, "click", function () {
            // console.log(e.target.getExtData())
            _this.dotItem(el.temp);
          });
          markerList.push(el.temp);
        }
      });
      this.map.add(markerList);
    },
    // 显示信息
    dotItem (val) {
      var infoWindow = new AMap.InfoWindow({
        // position: [val.longitude, val.latitude],
        offset: new AMap.Pixel(20, -30)
      });
      // const component = new InfoWindowContent().$mount()
      infoWindow.setContent(val.content);
      // // document.getElementById('infoimg').src = '@/assets/position.png'
      infoWindow.open(this.map, val.getPosition());
    }
  }
};
</script>

<style  lang="scss">
// .smoke {
//   width: 100vw;
//   height: 100%;
//   background: #03101a;
// }
.amap-info-content {
  background: #07233d;
  width: 740px;
  color: orange;
  font {
    font-size: 14px;
    line-height: 28px;
  }
}
.water {
  background: #000001;
  padding-top: 1px;
  position: relative;
  height: 100%;
  #fullscreen {
    width: 100vw;
    height: calc(100vh - 85px);
  }
  .left {
    width: 549px;
    position: absolute;
    z-index: 18;
    top: 66px;
    // bottom: 0;
    left: 34px;
  }
  .right {
    width: 549px;
    position: absolute;
    z-index: 18;
    top: 66px;
    right: 34px;
  }
}

.info {
  background: none;
}

.amap-marker-label {
  border: none;
  background: none;
  color: white;
}
</style>
