<template>
  <div>
    <div class="title"></div>
    <div class="linemain">
      <img src="../../../../assets/water/logo1.png" alt class="imgsize" />
      <div class="linetext">
        学校分布（含分校区）
        <div class="linebot">
          <span class="hotelnum">{{schoolTotal}}</span>
          <span class="danwei">所</span>
        </div>
      </div>
    </div>
    <div class="linemain">
      <img src="../../../../assets/water/logo2.png" alt class="imgsize" />
      <div class="linetext">
        设备数量
        <div class="linebot">
          <span class="hotelnum1">{{deviceTotal}}</span>
          <span class="danwei">台</span>
        </div>
      </div>
    </div>
    <div class="botline">
      <div class="linemain">
        <img src="../../../../assets/water/logo3.png" alt class="imgsize" />
        <div class="linetext">
          在线设备
          <div class="linebot">
            <span class="hotelnum1">{{onlineDeviceQuantity}}</span>
            <span class="danwei">台</span>
          </div>
        </div>
      </div>
      <div class="linemain">
        <img src="../../../../assets/water/logo4.png" alt class="imgsize" />
        <div class="linetext">
          本月新增设备
          <div class="linebot">
            <span class="hotelnum1">{{newDeviceQuantity}}</span>
            <span class="danwei">台</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMaintenanceStatistic } from "@/api";

export default {
  data() {
    return {
      deviceTotal: 0,
      newDeviceQuantity: 0,
      onlineDeviceQuantity: 0,
      schoolTotal: 0
    };
  },
  methods: {
    getflow() {
      deviceMaintenanceStatistic().then(res => {
        this.deviceTotal = res.deviceTotal;
        this.newDeviceQuantity = res.newDeviceQuantity;
        this.onlineDeviceQuantity = res.onlineDeviceQuantity;
        this.schoolTotal = res.schoolTotal;
        console.log(res);
      });
    }
  },
  created() {
    this.getflow();
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 50px;
  width: 549px;
  background: url("../../../../assets/water/lefttop.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
}
.linemain {
  color: white;
  display: flex;
  align-items: center;
  margin-top: 30px;
  .imgsize {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .linetext {
    font-size: 18px;
    .linebot {
      margin-top: 5px;
      .hotelnum {
        color: #ffd600;
        font-size: 26px;
        font-weight: bold;
      }
      .hotelnum1 {
        color: #00ddff;
        font-size: 26px;
        font-weight: bold;
      }
      .danwei {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}
.botline {
  display: flex;
  align-items: center;
  padding-right: 40px;
  justify-content: space-between;
}
</style>