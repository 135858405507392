<template>
  <div class="waterheader">
    <span class="header">杭州市上城区中小学饮水卫生监管平台</span>
    <div class="logoleft">
      <img src="../../../../assets/water/logo.png" alt class="logoimg" />
      上城区卫生健康行政执法队
    </div>
    <div class="headerright" @click="goBack">
      <span id="mytime"></span>
      <img src="../../../../assets/water/home.png" alt class="homeimg" />
      进入平台
    </div>
  </div>
</template>

<script>
function showTime () {
  let nowtime = new Date();
  let year = nowtime.getFullYear();
  let month =
    nowtime.getMonth() + 1 > 9
      ? nowtime.getMonth() + 1
      : "0" + (nowtime.getMonth() + 1);
  let date =
    nowtime.getDate() > 9 ? nowtime.getDate() : "0" + nowtime.getDate();
  document.getElementById("mytime").innerText =
    year + "-" + month + "-" + date + " " + nowtime.toLocaleTimeString();
}
setInterval(showTime, 1000);

export default {
  data () {
    return {};
  },
  created () { },
  mounted () { },
  methods: {
    goBack () {
      window.open(`https://watersupervision.terabits.cn/device?token=${this.$store.getters.token}`)

    }
  }
};
</script>

<style scoped lang="scss">
.waterheader {
  margin-top: 10px;
  height: 74px;
  width: 100%;
  background: url("../../../../assets/water/header.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .header {
    background-image: -webkit-linear-gradient(top, #8cd9fd, #dbf4ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 36px;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
  .logoleft {
    position: absolute;
    left: 20px;
    top: 30px;
    font-size: 26px;
    color: white;
    display: flex;
    align-items: center;
    .logoimg {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .headerright {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 35px;
    font-size: 24px;
    color: white;
    display: flex;
    align-items: center;
    #mytime {
      font-size: 20px;
    }
    .homeimg {
      width: 22px;
      height: 22px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>
